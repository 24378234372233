export class Quadrant_1 {
  id!: number
  title!: string
  description!: string
  code!: string
  type!: string | null
  order!: number
  created_at!: Date
  updated_at!: Date
  quadrant!: number
  employee!: null
}
