import { Quadrant, Matrix } from '.'

export class Correlation {
  id!: number
  correlation!: string
  matrix_id!: number
  quadrant_content_principal_id!: number
  quadrant_content_right_id!: number
  matrix!: Matrix
  quadrant_content_principal!: Quadrant
  quadrant_content_right!: Quadrant
  created_at!: Date
  updated_at!: Date
}
