export class Quadrant_2 {
  id!: number | null
  title!: string
  description!: string | null
  code!: number
  type!: string | null
  order!: number
  created_at!: Date
  updated_at!: Date
  quadrant!: number
  employee!: null
}
