import { User } from '@/Models'

export class Collection {
  id!: number
  name!: string
  card_color!: string
  card_icon!: string | null
  matrix_number!: number
  user!: User
  user_id!: number
  created_at!: Date
  updated_at!: Date
}
