import { Department, User } from './index'
export class Employed {
  id!: number | null
  department_id!: number | null
  identity_card!: number | null
  first_name!: string | null
  middle_name!: string | null
  last_name!: string | null
  email!: string | null
  user_id!: number | null
  user!: User | null
  userName!: string
  password!: string | null
  department!: Department | null
  allow_cms_access!: boolean | null

  created_at!: Date | null
  updated_at!: Date | null
  delete_at!: Date | null
}
