import { Quadrant } from '.'

export class Content {
  id!: number
  title!: string
  description!: string
  order!: number
  code!: string
  type!: string
  quadrant!: Quadrant
  quadrant_id!: number
}
