export class QuadrantsRelationship {
  father_data!: {
    colletion: string
    matrix: string
    quadrant: string
    element: string
    full_url: string
    matrix_id: number
  }
  relationship_data!: [
    {
      url: string
      type_relationship: string
      priority_relationship: string
      register_id: number
    },
  ]
}
