import { Department, Employed, MatrixMetric, Perspectives } from '@/Models'

export class Metric {
  id!: number | null
  balance_scorecard!: {
    id: number
    title: string
    is_edit: boolean
    year: number
    create_at: Date
    update_at: Date
    delete_at: Date
  }
  title!: string
  metric_value_type!: string
  metric_dataset!: any
  create_at!: Date
  update_at!: Date
  delete_at!: Date
  nominal_value!: number
  lower_limit!: number
  upper_limit!: number
  in_balcance_score_card!: boolean
  indicator:
    | {
        id: null
        title: string
      }
    | undefined
  department!: Department
  matrix_metric!: MatrixMetric
  perspective?: Perspectives

  date?: Date
  indicator_id!: number | null | undefined
  employed_id!: number | null | undefined
  employed!: Employed
  matrix_metric_id!: number | null | undefined
  department_id!: number | null
  perspective_id!: number
  balance_scorecard_id!: number
}
