export class User {
  id!: number
  username!: string
  identity_card!: string
  first_name!: string
  middle_name!: string
  last_name!: string
  allow_access!: boolean
  style!: string
  photo!: string
  icon!: string
  last_password_change!: Date
  is_temp_login!: boolean
  days_password_change!: number
  date_created!: Date
  not_a_person!: boolean
  email!: string
  is_admin!: boolean
  is_staff!: boolean
  is_active!: boolean
  is_external!: boolean
  is_superuser!: boolean
  last_login!: Date
  password!: string
  language_data!: string
  language_app!: string
  groups!: any[]
  user_permissions!: any[]
}
