import { Content } from './Content'
import { Matrix } from './Matrix'

export class MatrixMetric {
  id!: number
  matrix_id!: number | null | undefined
  matrix!: Matrix
  activity_id!: number | null
  activity!: Content
  date!: Date | string
  metric_type!: string
}
