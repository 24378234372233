import {
  TypeRelationships,
  PriorityRelationships,
  Quadrant_1,
  Quadrant_2,
  User,
} from '@/Models'

export class MatrixRelationships {
  id!: number
  created_at!: Date
  updated_at!: Date
  type!: TypeRelationships
  priority!: PriorityRelationships
  quadrant_1!: Quadrant_1
  quadrant_2!: Quadrant_2
  user!: User
}
