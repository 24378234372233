import { Quadrant, Collection, User } from '.'

export class Matrix {
  id!: number
  name!: string
  description!: string
  search!: string
  north_quadrant!: Quadrant
  north_quadrant_id!: number
  south_quadrant!: Quadrant
  south_quadrant_id!: number
  west_quadrant!: Quadrant
  west_quadrant_id!: number
  east_quadrant!: Quadrant
  east_quadrant_id!: number
  resources_quadrant!: Quadrant
  resources_quadrant_id!: number
  collection!: Collection
  collection_id!: number
  user!: User
  user_id!: number
}
