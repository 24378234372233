import { Pages } from './Pages'
import { User } from './User'

export class ArticleEndPoint {
  id!: number | null
  title!: string
  description!: string
  url!: string | null
  image!: string | null
  pages!: Pages[]
  creator!: User | null
  created_at!: string
}
